<template>
  <div class="project">
    <alert :text="alertText" :type="alertType"></alert>
    <v-card
      v-if="project"
      outlined
    >
      <v-img
        :src="project.image"
        height="250px"
        class="align-end"
      >
        <v-chip
          v-if="project.status"
          class="status ms-5 mb-5 p-1"
          :color="status[project.status].color"
        >
          <span class="pe-1">
            {{ status[project.status].text }}
          </span>
          <span v-if="project.note" class="percentage">
            | {{ project.note }}
          </span>
        </v-chip>
      </v-img>

      <div class="d-flex flex-column flex-md-row justify-space-between mt-5 mx-5">
        <div>
          <h2>{{ project.title }}</h2>
          <h3 class="text-subtitle-1 mb-4">
            {{ project.capacity }} • {{ project.type }} • {{ project.region }}, {{ project.country }}
          </h3>
        </div>
        <project-links :project="project"></project-links>
      </div>

      <v-card-text>
        <p>{{ project.description }}</p>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col v-if="project.status != 4" cols="12" xs="12" sm="6">
            <project-details :project="project"></project-details>
          </v-col>
          <v-col v-if="project.status == 4" cols="12" xs="12" sm="6">
            <system-details :project="project" :project-energy="projectEnergy"></system-details>
          </v-col>
          <v-col cols="12" xs="12" sm="6">
            <blockchain-details :project="project"></blockchain-details>
          </v-col>
          <v-col v-if="project.status == 4" cols="12" xs="12" sm="6">
            <project-energy :project="project" :project-energy="projectEnergy"></project-energy>
          </v-col>
          <v-col v-if="project.status == 4" cols="12" xs="12" sm="6">
            <project-carbon :project="project" :project-energy="projectEnergy"></project-carbon>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue'
import BlockchainDetails from './BlockchainDetails.vue'
import SystemDetails from './SystemDetails.vue'
import ProjectLinks from './ProjectButtons.vue'
import ProjectDetails from './ProjectDetails.vue'
import ProjectEnergy from '../reports/ProjectEnergy.vue'
import ProjectCarbon from '../reports/ProjectCarbon.vue'

export default {
  name: 'Project',
  components: {
    Alert,
    BlockchainDetails,
    SystemDetails,
    ProjectLinks,
    ProjectDetails,
    ProjectEnergy,
    ProjectCarbon,
  },
  data() {
    return {
      project: {},
      projectData: {},
      projectEnergy: {},
      status: {
        1: { text: 'Comming Soon', color: 'primary' },
        2: { text: 'Available Now', color: 'info' },
        3: { text: 'Under Construction', color: 'success' },
        4: { text: 'Generating Power', color: 'warning' },
      },
      alertText: undefined,
      alertType: undefined,
    }
  },
  mounted() {
    this.project = this.$store.getters.getProject(this.$route.params.slug)
    this.projectData = this.$store.getters.getProjectData(this.$route.params.slug)
    this.getEnergy()
  },
  methods: {
    getEnergy() {
      if (this.project.inverterID) {
        this.$store.dispatch('loadProjectEnergy', this.project.inverterID).then((data) => {
          this.projectEnergy = data.map((item) => {
            return [item.timestamp, item.energy]
          })
        }).catch((e) => {
          console.error(e)
        })
      }
    },
  },
}
</script>

<style scoped>
  .status {
    opacity: 0.9;
  }
</style>
