<template>
  <v-dialog
    v-if="project.status == 2"
    v-model="investmentDialog"
    max-width="500px"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-none mr-4 mb-4"
        color="tertiary"
        rounded
        small
        v-bind="attrs"
        v-on="on"
      >
        Invest Now
        <v-icon right>
          {{ icons.mdiWallet }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="ps-3 text-h5">Invest In Project</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="investmentDialog = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <investment-form :project="project"></investment-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiWallet, mdiClose } from '@mdi/js'
import InvestmentForm from '../invest/InvestmentForm.vue'

export default {
  name: 'InvestButton',
  setup() {
    return {
      icons: {
        mdiWallet,
        mdiClose,
      },
    }
  },
  components: {
    InvestmentForm,
  },
  props: {
    project: {
      type: Object.new,
      default: {},
    },
  },
  data() {
    return {
      investmentDialog: false,
    }
  },
}
</script>
