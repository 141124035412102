<template>
  <div class="project-buttons mr-n4">
    <invest-button :project="project"></invest-button>
    <v-btn
      v-if="project.investorPack"
      class="text-none mr-4 mb-4"
      color="secondary"
      :href="project.investorPack"
      download
      rounded
      small
    >
      Investor Pack
      <v-icon right>
        {{ icons.mdiDownload }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="project.status <= 3"
      class="text-none mr-4 mb-4"
      color="secondary"
      :to="{ name: 'calculator', params: { slug: project.slug }}"
      rounded
      small
    >
      Calculator
      <v-icon right>
        {{ icons.mdiCalculator }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="project.status == 4"
      class="text-none mr-4 mb-4"
      color="secondary"
      :to="{ name: 'report', params: { slug: project.slug }}"
      rounded
      small
    >
      Report
      <v-icon right>
        {{ icons.mdiChartBox }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import {
  mdiWallet,
  mdiDownload,
  mdiCalculator,
  mdiChartBox,
} from '@mdi/js'
import InvestButton from './InvestButton.vue'

export default {
  name: 'ProjectButtons',
  components: { InvestButton },
  props: {
    project: {
      type: Object.new,
      default: {},
    },
  },
  setup() {
    return {
      icons: {
        mdiWallet,
        mdiDownload,
        mdiCalculator,
        mdiChartBox,
      },
    }
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
}
.theme--dark .metric {
  border-color: #ffffff12;
}
.theme--light .metric {
  border-color: #12385012;
}
.label {
  width: 150px;
  display: inline-block;
}
</style>
