<template>
  <v-card outlined>
    <v-card-title>
      Project Details
    </v-card-title>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">Cost:</span>
      <v-chip class="mr-3">
        <span>{{ project.cost | toCurrency }}</span>
      </v-chip>
      <span>{{ project.percentageSold }}% sold</span>
    </p>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">Capacity:</span>
      <span>{{ project.capacity }}</span>
    </p>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">Lifetime:</span>
      <span>{{ project.lifetime }} years</span>
    </p>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">IRR:</span>
      <span>{{ project.IRR | toNumber }}%</span>
    </p>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">Contract:</span>
      <span>{{ project.contractType }}</span>
      <span v-if="project.investmentAgreement">
        • <a :href="project.investmentAgreement" download class="text-decoration-none">Investment Agreement</a>
      </span>
    </p>
  </v-card>
</template>

<script>
import { mdiEthereum } from '@mdi/js'

export default {
  name: 'BlockchainDetails',
  props: {
    project: {
      type: Object.new,
      default: {},
    },
  },
  setup() {
    return {
      icons: {
        mdiEthereum,
      },
    }
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
}
.theme--dark .metric {
  border-color: #ffffff12;
}
.theme--light .metric {
  border-color: #12385012;
}
.label {
  width: 150px;
  line-height: 32px;
  display: inline-block;
}
.v-chip .v-avatar {
  height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
}
</style>
