<template>
  <v-card outlined>
    <v-card-title>
      Blockchain Details
    </v-card-title>
    <p v-if="project.chain_id" class="metric px-4 py-2 ma-0">
      <span class="label">Blockchain:</span>
      <v-chip v-if="project.chain_id" class="pr-4 mr-1">
        <v-avatar class="mr-2">
          <img :src="require(`@/assets/platforms/${$store.getters.platformFor(project.chain_id)}.svg`)" alt="Blockchain">
        </v-avatar>
        {{ $store.getters.platformName(project.chain_id) }}
      </v-chip>
      <add-to-wallet :chain-id="project.chain_id">
        <span>Switch to or add this blockchain to your wallet.</span>
      </add-to-wallet>
    </p>
    <p v-if="project.stablecoinAddress" class="metric px-4 py-2 ma-0">
      <span class="label">Stablecoin:</span>
      <a v-if="project.chain_id" :href="`${$store.getters.explorerUrlFor(project.chain_id)}/token/${project.stablecoinAddress}`" target="_blank" class="text-decoration-none">
        {{ project.stablecoinName }} ({{ project.stablecoinSymbol }})
      </a>
      <add-to-wallet :token-address="project.stablecoinAddress" :token-symbol="project.stablecoinSymbol">
        <span>Add this stablecoin to your wallet.</span>
      </add-to-wallet>
    </p>
    <p v-if="project.address" class="metric px-4 py-2 ma-0">
      <span class="label">Token:</span>
      <a v-if="project.chain_id" :href="`${$store.getters.explorerUrlFor(project.chain_id)}/token/${project.address}`" target="_blank" class="text-decoration-none">
        {{ project.tokenName }} ({{ project.tokenSymbol }})
      </a>
      <add-to-wallet :token-address="project.address" :token-symbol="project.tokenSymbol">
        <span>Add this token to your wallet.</span>
      </add-to-wallet>
    </p>
  </v-card>
</template>

<script>
import { mdiEthereum } from '@mdi/js'
import AddToWallet from '@/components/AddToWallet.vue'

export default {
  name: 'BlockchainDetails',
  components: {
    AddToWallet,
  },
  props: {
    project: {
      type: Object.new,
      default: {},
    },
  },
  setup() {
    return {
      icons: {
        mdiEthereum,
      },
    }
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
}
.theme--dark .metric {
  border-color: #ffffff12;
}
.theme--light .metric {
  border-color: #12385012;
}
.label {
  width: 150px;
  line-height: 32px;
  display: inline-block;
}
.v-chip .v-avatar {
  height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
}
</style>
