<template>
  <v-card outlined min-height="300px">
    <v-card-title>
      Energy Generated
    </v-card-title>
    <div class="metric pt-2 pr-4 pb-1">
      <highcharts :options="periodicRevenueChartOptions" style="height: 300px"></highcharts>
    </div>
  </v-card>
</template>

<script>
import { Chart } from 'highcharts-vue'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    project: {
      type: Object.new,
      default: {},
    },
    projectEnergy: {
      type: Array.new,
      default: () => [],
    },
  },
  data() {
    return {
      periodicRevenueChartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
          events: {
            render: function() {
              this.reflow()
            },
          },
        },
        title: {
          text: null,
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: {point.y:,.0f}kWh',
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          title: {
            text: 'Date',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Energy (kWh)',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            shadow: false,
            stacking: 'normal',
          },
        },
        legend: {
          enabled: false,
        },
        colors: [
          // colors.blue.lighten2,
          // colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [],
      },
    }
  },
  watch: {
    projectEnergy: function() {
      this.setSeries()
    },
  },
  mounted() {
    this.setSeries()
  },
  methods: {
    setSeries() {
      var series = [
        {
          name: 'Energy Generated',
          data: this.projectEnergy,
        },
      ]
      this.periodicRevenueChartOptions.series = series
    },
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
}
.theme--dark .metric {
  border-color: #ffffff12;
}
.theme--light .metric {
  border-color: #12385012;
}
.label {
  width: 150px;
  display: inline-block;
}
</style>
