<template>
  <span v-if="ethereum">
    <v-tooltip :top="top" :right="right" :bottom="bottom" :left="left" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          x-small
          v-bind="attrs"
          v-on="on"
          @click="add"
        >
          <v-icon small class="text--disabled">
            {{ icons.mdiPlusCircle }}
          </v-icon>
        </v-btn>
      </template>
      <slot></slot>
    </v-tooltip>
  </span>
</template>

<script>
import { mdiPlusCircle } from '@mdi/js'
import { ethers } from 'ethers'

export default {
  setup() {
    return {
      icons: {
        mdiPlusCircle,
      },
    }
  },
  props: {
    chainId: {
      type: String,
      default: null,
    },
    tokenAddress: {
      type: String,
      default: null,
    },
    tokenSymbol: {
      type: String,
      default: null,
    },
    top: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ethereum() {
      return Boolean(window.ethereum)
    },
  },
  methods: {
    add() {
      if (this.chainId) {
        this.addChain(this.chainId)
      } else if (this.tokenAddress && this.tokenSymbol) {
        this.addToken(this.tokenAddress, this.tokenSymbol)
      }
    },
    addChain(chain_id) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chain_id }],
      }).then(() => {
        console.log('Chain switched')
      }).catch((error) => {
        if (error.code === 4902) {
          window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chain_id,
                chainName: this.$store.getters.platformName(chain_id),
                rpcUrls: [this.$store.getters.rpcUrlFor(chain_id)],
                blockExplorerUrls: [this.$store.getters.explorerUrlFor(chain_id)],
                nativeCurrency: {
                  symbol: this.$store.getters.symbolFor(chain_id),
                  decimals: 18,
                },
              },
            ],
          }).then(() => {
            console.log('Chain added')
          }).catch((e) => {
            console.error(e)
          })
        }
      })
    },
    addToken(token_address, token_symbol) {
      var provider = new ethers.providers.Web3Provider(window.ethereum)
      var abi = ['function decimals() view returns (uint8)']
      var contract = new ethers.Contract(token_address, abi, provider)

      contract.decimals().then((decimals) => {
        window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: token_address,
              symbol: token_symbol,
              decimals: decimals,
            },
          },
        }).then((success) => {
          if (success) {
            console.log('Token added')
          }
        }).catch((e) => {
          console.error(e)
        })
      }).catch((e) => {
        console.error(e)
      })
    },
  },
}
</script>
