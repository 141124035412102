<template>
  <v-card outlined>
    <v-card-title>
      System Details
    </v-card-title>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">Operating time:</span>
      <span>{{ operating_days | toNumber }} days</span>
    </p>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">Energy generated:</span>
      <span>{{ energy_generated | toNumber }} kWh</span>
    </p>
    <p class="metric px-4 py-2 ma-0">
      <span class="label">Carbon avoided:</span>
      <span>{{ carbon_avoided | toNumber }} kg</span>
    </p>
  </v-card>
</template>

<script>
import { mdiEthereum } from '@mdi/js'

export default {
  name: 'SystemDetails',
  props: {
    project: {
      type: Object.new,
      default: {},
    },
    projectEnergy: {
      type: Array.new,
      default: () => [],
    },
  },
  setup() {
    return {
      icons: {
        mdiEthereum,
      },
    }
  },
  computed: {
    operating_days() {
      return (Date.now() - this.project.connectionDate) / 86400000
    },
    energy_generated() {
      var energy = 0
      if (this.projectEnergy.length) {
        energy = this.projectEnergy.reduce((partialSum, item) => {
          return partialSum + item[1]
        }, 0)
      }
      return energy
    },
    carbon_avoided() {
      return this.energy_generated * this.project.carbonFactor
    },
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
}
.theme--dark .metric {
  border-color: #ffffff12;
}
.theme--light .metric {
  border-color: #12385012;
}
.label {
  width: 150px;
  line-height: 32px;
  display: inline-block;
}
</style>
